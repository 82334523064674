import React, { useRef, useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import Select from 'react-select';
import { css } from '@emotion/core';
import { PacmanLoader } from 'react-spinners';
import { Alert, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Container, Table, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Pagination, PaginationItem, PaginationLink,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand } from 'reactstrap';
// import classnames from 'classnames';
import moment from 'moment';
import Api from '@bowtie/api';
import auth from './auth';
import { pubnub } from './lib';
import './App.css';

const bitbucketClientId = process.env.REACT_APP_BITBUCKET_CLIENT_ID || 'nxpBCkZfHP2gsvRDaA';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 50px auto;
  border-color: red;
  color: #666;
  width: 100px;
`;

const apiConfig = {
  stage: 'dev',
  prefix: 'api',
  version: 'v1',
  secureOnly: false,
  authorization: 'Bearer'
};

const profileMap = {
  'local': {
    'local': 'http://localhost:4000',
    'example-github': 'https://x88s4y3p0k.execute-api.us-east-2.amazonaws.com',
    'example-bitbucket': 'https://18j31iq14c.execute-api.us-east-2.amazonaws.com',
    'usac-laravel-api': 'https://0obays8ra5.execute-api.us-east-1.amazonaws.com',
    'usac-permitting': 'https://mmfafed4c2.execute-api.us-east-1.amazonaws.com',
    'usac-membership': 'https://zgebly7cii.execute-api.us-east-1.amazonaws.com',
    'usac-checkout': 'https://ijagsg9z64.execute-api.us-east-1.amazonaws.com',
    'usac-admin-angular': 'https://ug61enlze7.execute-api.us-east-1.amazonaws.com',
  },
  'example': {
    'example-github': 'https://x88s4y3p0k.execute-api.us-east-2.amazonaws.com',
    'example-bitbucket': 'https://18j31iq14c.execute-api.us-east-2.amazonaws.com'
  },
  'usac': {
    'usac-admin-angular': 'https://ug61enlze7.execute-api.us-east-1.amazonaws.com',
    'usac-laravel-api': 'https://0obays8ra5.execute-api.us-east-1.amazonaws.com',
    'usac-permitting': 'https://mmfafed4c2.execute-api.us-east-1.amazonaws.com',
    'usac-membership': 'https://zgebly7cii.execute-api.us-east-1.amazonaws.com',
    'usac-checkout': 'https://ijagsg9z64.execute-api.us-east-1.amazonaws.com',
    'usac-craft': 'https://0jkfkvz8yl.execute-api.us-east-1.amazonaws.com',
    'usac-example': 'https://171gukzqbj.execute-api.us-east-1.amazonaws.com'
  }
};

const activeProfile = Object.keys(profileMap).includes(process.env.REACT_APP_PROFILE) ? process.env.REACT_APP_PROFILE : Object.keys(profileMap)[0];

const serviceMap = profileMap[activeProfile];

const getStorage = (key, defaultValue) => {
  let value = localStorage.getItem(key) || defaultValue;

  if (!value) {
    return defaultValue;
  }

  try {
    value = JSON.parse(value);
  } catch (err) {
    console.debug(err);
  }

  return value;
};

const setStorage = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

function App() {
  const [ token, setToken ] = useState(getStorage('token'));
  const [ alertOpen, setAlertOpen ] = useState(false);
  const [ alertText, setAlertText ] = useState();
  const [ alertColor, setAlertColor ] = useState('info');
  const [ isLoadingBuilds, setIsLoadingBuilds ] = useState(false);
  const [ isLoadingDeploys, setIsLoadingDeploys ] = useState(false);
  const [ isLoadingLogs, setIsLoadingLogs ] = useState(false);
  const [ logsModalOpen, setLogsModalOpen ] = useState(false);
  const [ activeBuild, setActiveBuild ] = useState({});
  const [ activeBuildLogs, setActiveBuildLogs ] = useState('');
  const [ service, setService ] = useState(getStorage('service', 'usac-permitting'));
  const [ serviceInfo, setServiceInfo ] = useState({});
  const [ stacks, setStacks ] = useState([]);
  const [ tags, setTags ] = useState([]);
  const [ currentBuilds, setCurrentBuilds ] = useState([]);
  const [ currentDeploys, setCurrentDeploys ] = useState([]);
  const [ builds, setBuilds ] = useState([]);
  const [ deploys, setDeploys ] = useState([]);
  const [ activeTab, setActiveTab ] = useState(getStorage('activeTab', 'deploys'));
  const [ buildParams, setBuildParams ] = useState(getStorage('buildParams', {}));
  const [ deployParams, setDeployParams ] = useState(getStorage('deployParams', {}));
  const [ page, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(getStorage('perPage', 100));
  const [ pageCount, setPageCount ] = useState(0);
  const [ isNavOpen, setIsNavOpen ] = useState(false);

  const logsEndEl = useRef(null);

  const toggleNav = () => setIsNavOpen(prevState => !prevState);
  const toggleLogsModal = () => setLogsModalOpen(prevState => !prevState);
  // const toggleAlert = () => setAlertOpen(prevState => !prevState);
  const dismissAlert = () => setAlertOpen(false);

  const calcDuration = (obj) => {
    const start = moment(obj.createdAt);
    const end = moment(obj.updatedAt);
    const duration = moment.duration(end.diff(start));

    Object.assign(obj, { start, end, duration });

    return obj;
  };

  const mergeBuildParams = (newParams) => {
    setBuildParams(Object.assign({}, buildParams, newParams));
  };

  const addBuildParam = (key, value) => {
    mergeBuildParams({ [key]: value });
  };

  const removeBuildParam = (key) => {
    const newParams = Object.assign({}, buildParams);

    if (key && newParams[key]) {
      delete newParams[key];
    }

    setBuildParams(newParams);
  };

  const mergeDeployParams = (newParams) => {
    setDeployParams(Object.assign({}, deployParams, newParams));
  };

  const addDeployParam = (key, value) => {
    mergeDeployParams({ [key]: value });
  };

  const removeDeployParam = (key) => {
    const newParams = Object.assign({}, deployParams);

    if (key && newParams[key]) {
      delete newParams[key];
    }

    setDeployParams(newParams);
  };

  const logout = () => {
    setStorage('token', '');
    setToken(null)
  };

  const setAlert = useCallback((text, color = 'info') => {
    setAlertText(text);
    setAlertColor(color);
    setAlertOpen(true);
  }, []);

  const login = useCallback(() => {
    if (!serviceInfo || !serviceInfo['source'] || !serviceInfo['source']['type']) {
      return;
    }

    switch(serviceInfo['source']['type']) {
      case 'BITBUCKET':
        window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${bitbucketClientId}&response_type=token`;
        break;
      case 'GITHUB':
        auth.login();
        break;
      default:
        console.warn('Unknown service source type', serviceInfo['source']['type']);
        break;
    }
  }, [ serviceInfo ]);

  const api = useCallback(() => {
    try {
      const root = serviceMap[service] || serviceMap[0];
      const api = new Api(Object.assign({}, apiConfig, { root }));

      api.authorize({
        token: () => getStorage('token')
      });

      api.on('error', (resp) => {
        resp.json().then(data => {
          setAlert(data.message || 'Unknown Error', 'danger');
        }).catch(err => {
          console.warn(err);
          setAlert(err.message || 'Unknown Error', 'danger');
        });
      });

      api.on('401', (resp) => {
        logout();
      });

      return api;
    } catch (err) {
      console.warn(err);

      const defaultService = Object.keys(serviceMap)[0];

      setStorage('service', defaultService);

      const api = new Api(Object.assign({}, apiConfig, { root: serviceMap[defaultService], secureOnly: false }));

      api.authorize({
        token: () => getStorage('token')
      });

      api.on('error', (resp) => {
        resp.json().then(data => {
          setAlert(data.message || 'Unknown Error', 'danger');
        }).catch(err => {
          console.warn(err);
          setAlert(err.message || 'Unknown Error', 'danger');
        });
      });

      return api;
    }
  }, [ service, setAlert ]);

  const selectBuild = (build) => {
    setActiveBuild(build);
    setIsLoadingLogs(true);
    setActiveBuildLogs('');
    toggleLogsModal();

    api().get(`builds/${build.id}/logs`).then(resp => resp.json()).then(resp => {
      setActiveBuildLogs(resp.logs.replace(/(.\[[0-9]+[a-z;]+)/ig, ''));
      setIsLoadingLogs(false);
    });
  };

  const changeService = (serviceName) => {
    setBuildParams({});
    setDeployParams({});
    setStorage('buildParams', {});
    setStorage('deployParams', {});

    setService(serviceName);
    setIsNavOpen(false);
    setStorage('service', serviceName);
  };

  const getPages = (max, start = 1) => {
    const pages = [];

    for (let i = start; i <= max; i++) {
      pages.push(i);
    }

    return pages;
  };

  const getStatusIcon = (status) => {
    switch(status.toUpperCase()) {
      case 'SUCCEEDED':
        return 'check';
      case 'FAILED':
        return 'exclamation-circle';
      case 'IN_PROGRESS':
      default:
        return 'clock';
    }
  };

  const getStatusColor = (status) => {
    switch(status.toUpperCase()) {
      case 'SUCCEEDED':
        return 'success';
      case 'FAILED':
        return 'danger';
      case 'IN_PROGRESS':
      default:
        return 'warning';
    }
  };

  const loadStacks = useCallback(() => {
    return api().get(`deploys/stacks`).then(resp => resp.json()).then(data => {
      setStacks(data);

      return Promise.resolve(data);
    });
  }, [ api ]);

  const loadTags = useCallback(() => {
    return api().get(`builds/tags`).then(resp => resp.json()).then(data => {
      setTags(data);

      return Promise.resolve(data);
    });
  }, [ api ]);

  useEffect(() => {
    setServiceInfo({})

    api().get('info').then(resp => resp.json()).then(data => {
      setServiceInfo(data);
    }).catch(err => {
      console.warn(err);
      setServiceInfo({});
    });
  }, [ api, service ]);

  useEffect(() => {
    if (page > pageCount) {
      setPage(1);
    }

    const indexStart = (page - 1) * perPage;
    const indexEnd = indexStart + perPage;

    const slicedBuilds = builds.slice(indexStart, indexEnd);
    const slicedDeploys = deploys.slice(indexStart, indexEnd);

    if (activeTab === 'deploys') {
      setPageCount(Math.ceil(deploys.length / perPage));
      setCurrentDeploys(slicedDeploys);
    } else if (activeTab === 'builds') {
      setPageCount(Math.ceil(builds.length / perPage));
      setCurrentBuilds(slicedBuilds);
    }
  }, [ page, perPage, pageCount, activeTab, builds, deploys ])

  const loadDeploys = useCallback(() => {
    return api().get(`deploys?${qs.stringify(deployParams)}`).then(resp => resp.json()).then(resp => {
      const data = resp.map(calcDuration);

      setDeploys(data);

      return Promise.resolve(data);
    });
  }, [ api, deployParams ]);

  const loadBuilds = useCallback(() => {
    return api().get(`builds?${qs.stringify(buildParams)}`).then(resp => resp.json()).then(resp => {
      const data = resp.map(calcDuration);
      setBuilds(data);

      return Promise.resolve(data);
    });
  }, [ api, buildParams ]);

  const deployBuild = (build, stack, tag = null) => {
    if (!tag || tag.trim() === '') {
      tag = build.build_number;
    }

    if (window.confirm(`Deploy '${tag}' (build #${build.build_number}) to ${stack}?`)) {
      setActiveTab('deploys')
      setIsLoadingDeploys(true)

      api().get(`builds/${build.id}/deploy/${stack}?${qs.stringify({ tag })}`).then(resp => {
        console.debug('Deployed:', resp)
        setAlert(`Deploying '${tag || build.build_number}' (build #${build.build_number}) to ${stack}`)
        setIsLoadingDeploys(false)
      }).catch(err => {
        console.warn(err)
        setIsLoadingDeploys(false)
      });
    }
  };

  useEffect(() => {
    if (!pubnub) {
      console.warn('PubNub is not configured. Real-time updates are disabled.');
      return
    }

    pubnub.subscribe({
      channels: [
        'builds',
        'deploys'
      ]
    });

    return () => {
      pubnub.unsubscribeAll();
    };
  }, []);

  useEffect(() => {
    if (!pubnub) {
      console.debug('Skip effect, PubNub is not configured');
      return
    }

    const createOrUpdate = (existing, subject) => {
      const updated = Object.assign([], existing);
      const index = existing.findIndex(item => item.id === subject.id);
      const item = calcDuration(subject);

      if (index > -1) {
        console.debug('Updating item:', item);
        Object.assign(updated[index], item);
      } else {
        console.debug('Creating item:', item);
        updated.unshift(item);
      }

      return updated;
    }

    const listener = {
      status: (statusEvent) => {
        console.debug('PN Status', statusEvent);
      },
      message: (msg) => {
        const { channel, message } = msg;
        const { action, subject } = message;

        if (message.service !== service) {
          console.debug('Ignoring message', message);
          return;
        }

        console.debug('MSG', { channel, action, subject });

        switch(channel) {
          case 'builds':
            setBuilds(createOrUpdate(builds, subject));
            break;
          case 'deploys':
            setDeploys(createOrUpdate(deploys, subject));
            break;
          default:
            console.warn('Received message for unexpected channel:', channel);
            break;
        }
      }
    };

    pubnub.addListener(listener);

    return () => {
      pubnub.removeListener(listener);
    };
  }, [ builds, deploys, service ]);

  useEffect(() => {
    const { pathname } = window.location

    if (pathname === '/redirect') {
      return auth.handleRedirect();
    } else if (pathname === '/callback') {
      return auth.handleCallback((err) => {
        if (err) {
          console.warn(err)
        }

        window.location.href = '/';
      });
    }

    const hashParams = qs.parse(window.location.hash.replace(/^#/, ''));

    if (hashParams['access_token']) {
      setToken(hashParams['access_token'])
      setStorage('token', hashParams['access_token']);

      window.location.href = '/';
    }

    if (hashParams['builds']) {
      setActiveTab('builds');
    } else if (hashParams['deploys']) {
      setActiveTab('deploys');
    }
  }, []);

  useEffect(() => {
    setStorage('activeTab', activeTab);
    setPage(1);
    setPageCount(0);
    setIsNavOpen(false);
  }, [ activeTab ]);

  useEffect(() => {
    loadStacks().then(stacks => {
      console.debug('Loaded stacks', stacks);
    });

    loadTags().then(tags => {
      console.debug('Loaded tags', tags);
    });
  }, [ loadStacks, loadTags ]);

  useEffect(() => {
    setStorage('deployParams', deployParams);
    setIsLoadingDeploys(true);
    setDeploys([]);

    loadDeploys().then(data => {
      console.debug('Loaded deploys:', data);
      setIsLoadingDeploys(false);
    }).catch(err => {
      console.warn(err);
      setIsLoadingDeploys(false);
    });
  }, [ loadDeploys, deployParams ]);

  useEffect(() => {
    setStorage('buildParams', buildParams);
    setIsLoadingBuilds(true);
    setBuilds([]);

    loadBuilds().then(data => {
      console.debug('Loaded builds:', data);
      setIsLoadingBuilds(false);
    }).catch(err => {
      console.warn(err);
      setIsLoadingBuilds(false);
    });
  }, [ loadBuilds, buildParams ]);

  useEffect(() => {
    dismissAlert();
  }, [ activeTab, service, token ]);

  const deployableTags = (available, deploy) => {
    if (deploy.env === 'production' || /prod(uction)?/.test(deploy.stack)) {
      return available.filter(t => /v[0-9]+\.[0-9]+\.[0-9]+/.test(t));
    } else {
      return available;
    }
  };

  const findBuildByTag = (tag) => {
    return builds.find(build => build.build_tags && build.build_tags.includes(tag));
  };

  // const findDeployByTag = (tag) => {
  //   return deploys.find(deploy => deploy.tag === tag);
  // };

  const scrollBottomLogs = useCallback(() => {
    if (!logsEndEl || !logsEndEl.current) {
      console.warn('Cannot scroll without ref for "logsEndEl"');
      return;
    }

    logsEndEl.current.scrollIntoView({ behavior: 'smooth' });
  }, [ logsEndEl ]);

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <span className="logo">
            <img alt="Bowtie CI Logo"src="/logo.png" />
          </span>
          &nbsp;
          Bowtie CI
        </NavbarBrand>
        <NavbarToggler onClick={toggleNav} />
        <Collapse isOpen={isNavOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem active={activeTab === 'deploys'}>
              <NavLink href="#deploys" onClick={() => setActiveTab('deploys')}>
                <Badge color='info'>{deploys.length}</Badge>
                Deploys
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 'builds'}>
              <NavLink href="#builds" onClick={() => setActiveTab('builds')}>
                <Badge color='info'>{builds.length}</Badge>
                Builds
              </NavLink>
            </NavItem>
          </Nav>
          <div>
            <Select
              className='service-search'
              value={{ value: service, label: service }}
              placeholder='Select a service ...'
              options={Object.keys(serviceMap).map(s => ({ value: s, label: s }))}
              onChange={({value}) => changeService(value)}
            />
          </div>
        </Collapse>
      </Navbar>

      {alertOpen && alertText && alertText.trim() !== '' && (
        <Container fluid>
          <Alert
            color={alertColor}
            isOpen={alertOpen}
            toggle={dismissAlert}
          >
            {alertText}
          </Alert>
        </Container>
      )}

      {token && token.trim() !== '' ? (
        <Container fluid>
          {serviceInfo && serviceInfo['source'] && (
            <Row>
              <Col xs="10" sm="11">
                <h2 className="service-name text-info">
                  {service}
                </h2>
              </Col>
              <Col xs="2" sm="1">
                <h2 className="service-name text-right">
                  <a className="text-right" rel="noopener noreferrer" target="_blank" href={`${serviceInfo['source']['base']}/${serviceInfo['source']['repo']}`}>
                    <i className={`fab fa-${serviceInfo['source']['type'].toLowerCase()}`} />
                  </a>
                </h2>
              </Col>
            </Row>
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="builds">
              {Object.keys(buildParams).length > 0 && (
                <Row className='active-params'>
                  <Col>
                    {Object.keys(buildParams).map(key => (
                      <Button disabled key={key} color="primary" outline>
                        {key} <Badge color="secondary">{buildParams[key].toString()}</Badge>
                        &nbsp;
                        <span className='times' onClick={() => removeBuildParam(key)}>&times;</span>
                      </Button>
                    ))}
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm="12">
                  {<PacmanLoader size={50} color={'#007bff'} css={override} loading={isLoadingBuilds} />}
                  {!isLoadingBuilds && builds.length === 0 && (
                    <Alert color='warning'>These are not the builds you're looking for...</Alert>
                  )}
                  {!isLoadingBuilds && builds.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Tags</th>
                          <th>Build</th>
                          <th>Branch</th>
                          <th>Author</th>
                          <th>Started</th>
                          <th>Duration</th>
                          <th>Logs</th>
                          <th>Deploy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentBuilds.map((build, i) => (
                          <tr key={i}>
                            <td data-label='Status' className="param status" onClick={() => addBuildParam('build_status', build.build_status)}>
                              <Button color={getStatusColor(build.build_status)}>
                                <i className={`fas fa-${getStatusIcon(build.build_status)}`}></i>
                                <span className='status-text'>
                                  &nbsp;
                                  {build.build_status}
                                </span>
                              </Button>
                            </td>
                            <td data-label='Tags' className="param status">
                              <Button title={build && build.build_tags ? build.build_tags.join(', ') : 'N/A'} disabled color={build && build.build_tags ? 'info' : 'secondary'}>
                                <span className='status-text'>
                                  {build && build.build_tags && build.build_tags.length}
                                  &nbsp;
                                </span>
                                <i className={`fas fa-tags`}></i>
                              </Button>
                            </td>
                            <td data-label='Build' className="param">
                              <Button disabled>
                                # {build.build_number}
                              </Button>
                            </td>
                            <td data-label='Branch' className="param" onClick={() => addBuildParam('source_branch', build.source_branch)}>
                              <Button outline color='primary'>{build.source_branch}</Button>
                            </td>
                            <td data-label='Author' className="param" onClick={() => addBuildParam('build_author', build.build_author)}>
                              <Button outline color='primary'>{build.build_author}</Button>
                            </td>
                            <td data-label='Started'>
                              <Button outline disabled>{build.start.fromNow()}</Button>
                            </td>
                            <td data-label='Duration'>
                              <Button outline disabled>{build.duration.humanize()}</Button>
                            </td>
                            <td data-label='Logs' className="param" onClick={() => selectBuild(build)}>
                              <Button outline color='info'>Logs</Button>
                            </td>
                            <td className="deploy">
                              <Select
                                options={stacks.map(s => ({ value: s, label: s }))}
                                isDisabled={build.build_status !== 'SUCCEEDED'}
                                onChange={({ value }) => deployBuild(build, value)}
                                placeholder='Deploy to ...'
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="deploys">
              {Object.keys(deployParams).length > 0 && (
                <Row className='active-params'>
                  <Col>
                    {Object.keys(deployParams).map(key => (
                      <Button disabled key={key} color="primary" outline>
                        {key} <Badge color="secondary">{deployParams[key].toString()}</Badge>
                        &nbsp;
                        <span className='times' onClick={() => removeDeployParam(key)}>&times;</span>
                      </Button>
                    ))}
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm="12">
                  {<PacmanLoader size={50} color={'#007bff'} css={override} loading={isLoadingDeploys} />}
                  {!isLoadingDeploys && deploys.length === 0 && (
                    <Alert color='warning'>These are not the deploys you're looking for...</Alert>
                  )}
                  {!isLoadingDeploys && deploys.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Stack</th>
                          <th>Version</th>
                          <th>Started</th>
                          <th>Duration</th>
                          <th>Deploy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentDeploys.map((deploy, i) => (
                          <tr key={i}>
                            <td data-label='Status' className="param status" onClick={() => addDeployParam('deploy_status', deploy.deploy_status)}>
                              <Button color={getStatusColor(deploy.deploy_status)}>
                                <i className={`fas fa-${getStatusIcon(deploy.deploy_status)}`}></i>
                                <span className='status-text'>
                                  &nbsp;
                                  {deploy.deploy_status}
                                </span>
                              </Button>
                            </td>
                            <td data-label='Stack' className="param" onClick={() => addDeployParam('stack', deploy.stack)}>
                              <Button outline color='primary'>{deploy.stack}</Button>
                            </td>
                            <td data-label='Tag' className="param" onClick={() => addDeployParam('tag', deploy.tag)}>
                              <Button outline color='primary'>{deploy.tag}</Button>
                            </td>
                            <td data-label='Started'>
                              <Button outline disabled>{moment(deploy.createdAt).fromNow()}</Button>
                            </td>
                            <td data-label='Duration'>
                              <Button outline disabled>{deploy.duration.humanize()}</Button>
                            </td>
                            <td className="deploy">
                              <Select
                                isDisabled={deploy.deploy_status === 'IN_PROGRESS'}
                                options={deployableTags(tags, deploy).map(t => ({ value: t, label: t }))}
                                onChange={({ value }) => deployBuild(findBuildByTag(value), deploy.stack, value)}
                                placeholder='Deploy tag ...'
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          {
            !isLoadingDeploys && !isLoadingBuilds && pageCount > 1 && (
              <Row className='pagination'>
                <Col sm='1'>
                  <Select
                    value={({ value: perPage, label: perPage })}
                    options={[25,50,100,200,500,1000].map(n => ({ value: n, label: n }))}
                    onChange={({value}) => setPerPage(value)}
                  />
                </Col>
                <Col sm='11'>
                  <Pagination aria-label={`${activeTab} navigation`}>
                    <PaginationItem>
                        <PaginationLink disabled={page <= 1} first onClick={() => setPage(1)} />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink disabled={page <= 1} previous onClick={() => setPage(page - 1)} />
                      </PaginationItem>
                      {getPages(pageCount).map(pageNum => (
                        <PaginationItem active={page === pageNum} key={pageNum}>
                          <PaginationLink onClick={() => setPage(pageNum)}>{pageNum}</PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationLink disabled={page >= pageCount} next onClick={() => setPage(page + 1)} />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink disabled={page >= pageCount} last onClick={() => setPage(pageCount)} />
                      </PaginationItem>
                    </Pagination>
                </Col>
              </Row>
            )
          }
          <Modal scrollable size='xl' isOpen={logsModalOpen} toggle={toggleLogsModal}>
            <ModalHeader toggle={toggleLogsModal}>
              {activeBuild && (
                <span>
                  {activeBuild.service_name} - Build #{activeBuild.build_number} [{activeBuild.build_status}] ({activeBuild.duration ? activeBuild.duration.humanize() : '...'})
                </span>
              )}
            </ModalHeader>
            <ModalBody>
              <PacmanLoader css={override} loading={isLoadingLogs} />
              {activeBuildLogs.trim() !== '' && (
                <div className='logs'>
                  <pre>{activeBuildLogs}</pre>
                  <div ref={logsEndEl} />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color='info'
                onClick={scrollBottomLogs}
              >
                Scroll End
                &nbsp;
                <i className="fas fa-caret-down" />
              </Button>
              <Button color="secondary" onClick={toggleLogsModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col>
              {serviceInfo && serviceInfo['source'] && (
                <div id="welcome">
                  {serviceInfo['source']['type'] === 'GITHUB' && (
                    <Button onClick={() => login()} block size='lg' color='secondary'>
                      <i className="fab fa-github" />
                      &nbsp;
                      Sign in with GitHub
                    </Button>
                  )}

                  {serviceInfo['source']['type'] === 'BITBUCKET' && (
                    <Button onClick={() => login()} block size='lg' color='primary'>
                      <i className="fab fa-bitbucket" />
                      &nbsp;
                      Sign in with Bitbucket
                    </Button>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default App;
